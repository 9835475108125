
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Suspense ,lazy } from 'react';
import LoadingIndicator from './Components/LoadingIndicator/LoadingIndicator';

const Connexion = lazy(() => import('./pages/Authentification/Connexion'));
const Conditions = lazy(() => import('./pages/Authentification/Conditions'));
const VerifyMail = lazy(() => import('./pages/Authentification/Verification/VerifyMail'));
const ResetPwd = lazy(() => import('./pages/Authentification/Verification/ResetPwd'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Logged = lazy(() => import('./utils/Logged'));
const PrivateRoute = lazy(() => import('./utils/PrivateRoute'));
const Demandes = lazy(() => import('./pages/Demandes/Demandes'));
const Declaratinons = lazy(() => import('./pages/Declarations/Declarations'));
const Employeur = lazy(() => import('./pages/FicheEmploye/Employeur'));
const Paiements = lazy(() => import('./pages/Payment/Paiements'));
const SuccessPayment = lazy(() => import('./pages/Payment/SuccessPayment'));
const FailurePayment = lazy(() => import('./pages/Payment/FailurePayment'));
const Salaires = lazy(() => import('./pages/Salary/Salaires'));
const Configurations = lazy(() => import('./pages/Configurations'));
const PaymentPage = lazy(() => import('./pages/cac/PaymentPage'));

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); 
    return () => clearTimeout(timer);
  }, []);


  return (
    
    <Router>
      <Suspense fallback={<LoadingIndicator />}>
      <Routes>
      <Route element={<PrivateRoute/>}>
          <Route path='/Accueil' element={<Dashboard/>} />
          <Route path='/Demandes' element={<Demandes/>} />
          <Route path='/Declarations' element={<Declaratinons/>} />
          <Route path='/Employeur' element={<Employeur/>} />
          <Route path='/Paiements' element={<Paiements/>} />
          <Route path='/Salaries' element={<Salaires/>} />
          <Route path='/Configurations' element={<Configurations/>} />

      </Route>

      <Route element={<Logged/>}>

            <Route path='/' element={<Connexion/>}/>
            <Route path='/conditions' element={<Conditions/>}/>
            <Route path='/verification' element={<VerifyMail/>}/>
            <Route path='/reinitialisation' element={<ResetPwd />}/>

      </Route>

      <Route path='/paiements/success' element={<SuccessPayment />}/>
      <Route path='/paiements/failure' element={<FailurePayment />}/>
      <Route path='/paymentpage/:paymentid' element={<PaymentPage />} />
    </Routes>
    </Suspense>
</Router>

  )
}

export default App;
